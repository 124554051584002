<template>
  <div v-if="selectedLabel">
    <!-- <div style="margin:20px auto;text-align: center;">
      <img src="/image/logo.gif" width="45%" />
    </div> -->
    <cube-tab-bar v-model="selectedLabel" show-slider inline class="headerNav" @change="changeTab">
      <cube-tab v-for="item in tabs" :label="item.label" :key="item.label" style="color:#ff9;flex-wrap: nowrap;">
        <div style="font-weight: bold;">{{ item.label }}</div>
      </cube-tab>
    </cube-tab-bar>
    <div v-if="selectedLabel === 'ABOUT US'">
      <Aboutus ref="aboutus"></Aboutus>
    </div>
    <div v-else-if="selectedLabel === 'PRODUCT'">
      <Product ref="product"></Product>
    </div>
    <div v-else>
      <Fair ref="fair"></Fair>
    </div>
  </div>
</template>

<script>
import Fair from '@/templates/fair/index'
import Product from '@/templates/product/index'
import Aboutus from '@/templates/aboutus/index'
export default {
  name: "Index",
  components: {
    Fair, Product, Aboutus
  },
  computed: {},
  data () {
    return {
      toast: null,
      selectedLabel: '',
      tabs: []
    };
  },
  async mounted () {
    this.toast = this.$createToast({
      txt: 'Loading...',
      mask: true
    })
    this.toast.show()
    await this.init()
    this.toast.hide()
  },
  methods: {
    async init () {
      const result = await this.$http.post('/mobile/index/index', { op: 'init' })
      this.tabs = result.data.menus
      this.selectedLabel = result.data.menuKey
    },

    changeTab (e) {
      this.selectedLabel = e
    },
  },
};
</script>

<style scoped>
.headerNav {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 14px;
  background: #115090;
  margin: 0 auto !important;
  margin-bottom: 0 !important;
  padding: 10px 0 !important;
}
</style>
